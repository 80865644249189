<template>
  <div
    class="is-flex is-align-center card font-sm"
    :class="{ 'no-image': !mealItem.image_url }"
  >
    <div class="eden-meal--image">
      <img
        v-if="mealItem.image_url"
        :src="getThumbnailFromCloudinary(mealItem.image_url)"
        alt="Image"
      />
      <img
        v-else
        :src="getImage('default/image-upload-icon.svg')"
        :alt="'Image'"
        :class="'default meal-image'"
      />
    </div>
    <span class="text-bold mr-10 meal-item">{{
      formatText(mealItem.name)
    }}</span>
    <span class="text-cursor">
      <span
        class="eden-icon-edit font-sm mr-1"
        @click="showEditItem = true"
      ></span>
      <span
        class="eden-icon-delete font-sm"
        @click="showRemoveItem = true"
      ></span>
    </span>
    <eden-confirm-dialog
      title="Remove meal item?"
      button-text="Yes, remove"
      button-type="danger"
      :show.sync="showRemoveItem"
      @confirm="removeItem"
    >
      <p>Are you sure you want to remove this meal item from the menu?</p>
    </eden-confirm-dialog>
    <eden-confirm-dialog
      title="Edit meal item image"
      button-text="Save changes"
      button-type="success"
      :show.sync="showEditItem"
      @confirm="saveChanges"
    >
      <p class="text-center mb-20">
        <span class="editText text-bold">Editing: {{ mealItem.name }}</span>
      </p>
      <eden-image-upload
        :image-size="60"
        :image-url.sync="image"
        :image-text="'meal item image'"
        :image-folder="'services/meal/combo'"
      />
    </eden-confirm-dialog>
  </div>
</template>

<script>
import mealItem from "@/requests/services/meal";

export default {
  name: "MealItem",
  props: {
    mealItem: {
      type: Object,
      default() {
        return {};
      },
    },
    action: {
      type: String,
      default: "add",
    },
  },
  data() {
    return {
      saved: false,
      showRemoveItem: false,
      showEditItem: false,
      image: this.mealItem.image_url,
    };
  },
  created() {
    this.payload();
  },
  methods: {
    removeItem() {
      this.$emit("remove", this.mealItem);
      this.showRemoveItem = false;
    },
    addItem() {
      let payload = this.parseData(this.form);
      let preset_combos = this.mealItem?.preset_combos;
      payload.preset_combos = preset_combos?.length
        ? JSON.stringify([...preset_combos])
        : null;
      payload.service = this.mealItem?.service_name || this.mealItem.service;
      payload.service_type_category =
        this.mealItem?.service_type_category || "single";
      payload.service_type_name = this.mealItem?.name;
      payload.side_types = this.mealItem?.side_types?.toString();
      payload.meal_type = this.mealItem?.meal_type;
      const allergy = payload?.allergyTags;
      payload.allergy = {
        seafood: allergy ? allergy.seafood : "not-included",
        shellfish: allergy ? allergy.shellfish : "not-included",
        nut: allergy ? allergy.nut : "not-included",
      };
      payload.image = this.image;
      return payload;
    },
    editItem() {
      const mealItems = this.$store.getters.meal_item_list;
      const menuId = this.menuId;
      const mealItem = mealItems.find((item) => item.id === menuId);
      let payload = this.parseData(this.form);
      let preset_combos = mealItem?.preset_combos;
      payload.preset_combos = preset_combos?.length
        ? JSON.stringify([...preset_combos])
        : null;
      payload.service = this.mealItem?.service_name || this.mealItem.service;
      payload.service_type_category =
        this.mealItem?.service_type_category || "single";
      payload.service_type_name = mealItem?.name;
      payload.meal_type = mealItem?.meal_type?.[0];
      payload.side_types = mealItem?.side_types?.toString();
      const allergy = payload?.allergyTags;
      payload.allergy = {
        seafood: allergy ? allergy.seafood : "not-included",
        shellfish: allergy ? allergy.shellfish : "not-included",
        nut: allergy ? allergy.nut : "not-included",
      };
      payload.image = this.image;
      return payload;
    },
    payload() {
      if (this.action === "add") {
        return this.addItem();
      }
      if (this.action === "edit") {
        return this.editItem();
      }
    },
    saveChanges() {
      const payload = {
        ...this.mealItem,
        image: this.image,
        image_url: this.image,
        meal_id: this.mealItem.id,
        tags_id: this.mealItem.tags_id,
      };
      mealItem
        .update(payload)
        .then((response) => {
          if (response.data.status) {
            this.showEditItem = false;
            this.$message.success(response.data.message);
            this.$emit("update:mealItem", payload);
          } else {
            this.$message.error(response.data.message);
            this.showEditItem = false;
          }
        })
        .catch((error) => {
          this.showEditItem = false;
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  background-color: var(--white);
  padding: 8px;
  border-radius: 8px;
  width: 300px;
  justify-content: space-between;
}

.no-image {
  border: 1px solid var(--eden-red-quinary);
}

.eden-meal--image {
  height: 36px;
  width: 36px !important;
  border-radius: 8px;
  background: #f4f4f9;
  margin-right: 20px;
  img {
    height: 100%;
    width: 100%;
    object-fit: contain;
    border-radius: 8px;
  }
  + div {
    width: 70%;
  }
}

.editText {
  background-color: var(--eden-orange-septenary);
  border-radius: 8px;
  padding: 8px;
}

.meal-item {
  width: 80px;
}
.meal-image {
  background: #e9fbf2;
}
</style>
