import axios from "axios";

export default {
  index({ service, page }) {
    return axios.get(`service_type/${service}/all?page=${page}`);
  },

  search(service, query) {
    return axios.get(`service_type/${service}/all/search/${query}`);
  },

  get(id) {
    return axios.get(`service_type/${id}`);
  },

  add(payload) {
    return axios.post("service_type", payload);
  },

  update(id, payload) {
    return axios.put(`service_type/${id}`, payload);
  },

  status(id, payload) {
    return axios.put(`service_type/${id}/update_activation_status`, payload);
  },

  delete(service, payload) {
    return axios.post(`service_type/${service}/expunge`, payload);
  },
};
