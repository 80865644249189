<template>
  <div v-loading="prefilling" class="eden-page-form wider">
    <el-form :model="form" label-position="top" ref="form">
      <template v-if="step === 1">
        <menu-one-time-window :menu-items.sync="form.menu_items" @updateMealItem="updateMealItem"
          :meal-class-items="mealClassItems" :action="'edit'" />
      </template>
      <template v-else-if="step === 2">
        <one-time-combinations-review :menu-items.sync="form.menu_items" @updateMealItem="updateMealItem"
          :action="$route.path.includes('/add') ? 'add' : 'edit'" />
      </template>
      <eden-form-section title="" subtitle="">
        <template slot="form-fields">
          <div class="eden-page-form__actions">
            <span class="eden-page-form__actions">
              <el-button plain @click="cancel" v-if="step === 1">Cancel</el-button>
              <el-button plain @click="step = 1" v-else>Back</el-button>
              <el-button v-if="showDraftButton" type="plain" :disabled="isDisabled || updating || adding"
                :loading="updating" @click="draft" class="mr-10">
                Save as draft
              </el-button>
            </span>
            <el-button v-if="step === 1" type="primary" @click="next" :disabled="isDisabled">Next
            </el-button>
            <el-button v-else type="primary" :loading="adding" @click="addMenu"
              :disabled="!isValidMenuItems || updating || adding">
              {{ showPublishTitle ? "Publish menu" : "Update menu" }}
            </el-button>
          </div>
        </template>
      </eden-form-section>
    </el-form>
    <eden-confirm-dialog :title="showPublishTitle ? 'Publish this menu?' : 'Edit this published menu?'
      " :button-text="showPublishTitle ? 'Yes, publish' : 'Yes, update'" button-type="success" :show.sync="showPublish"
      @confirm="publish">
      <p>
        Are you sure you want to
        {{ showPublishTitle ? "publish" : "edit" }}
        this menu?
      </p>
    </eden-confirm-dialog>
  </div>
</template>

<script>
import MenuOneTimeWindow from "@/components/Services/Meal/MealOneTimeOrder/NewMenuOneTime/MenuOneTimeWindow";
import OneTimeCombinationsReview from "@/components/Services/Meal/MealOneTimeOrder/NewMenuOneTime/OneTimeCombinationsReview";
import * as actions from "@/store/action-types";
import onetime from "@/requests/services/meal/onetime/index";

export default {
  name: "MenuOneTimeForm",
  props: {
    action: {
      type: String,
      default: "add",
    },
    serviceTypeId: {
      type: [String, Number],
      default: "",
    },
    serviceType: {
      type: Object,
      default() {
        return {};
      },
    },
    data: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  components: {
    MenuOneTimeWindow,
    OneTimeCombinationsReview,
  },
  data() {
    return {
      prefilling: true,
      step: 1,
      form: {
        menu_items: {
          "12pm": {
            time: "12pm",
            menu_item_category_id: 1,
            items: [],
          },
          "4pm": {
            time: "4pm",
            menu_item_category_id: 2,
            items: [],
          },
          "11am": {
            time: "11am",
            menu_item_category_id: 3,
            items: [],
          },
        },
        service: "Meal",
        image: null,
        combo_image: null,
        service_type_name: "",
        meal_type: "",
        class_category: null,
        side_category: "",
        main_with_sides: false,
        side_types: [],
        food_tags: null,
        chef_note: "",
        preset_combos: [],
      },
      mealClassItems: [],
      adding: false,
      updating: false,
      showPublish: false,
      isValidMenuItems: false,
      menuOptions: {
        "12pm": {
          time: "12pm",
          menu_item_category_id: 1,
          items: [],
        },
        "4pm": {
          time: "4pm",
          menu_item_category_id: 2,
          items: [],
        },
        "11am": {
          time: "11am",
          menu_item_category_id: 3,
          items: [],
        },
      },
    };
  },
  watch: {
    "form.menu_items": {
      handler() {
        if (this.location === "KE") {
          this.validateMenuItems(this.form.menu_items["11am"].items);
        } else {
          this.validateMenuItems(this.form.menu_items["12pm"].items);
          this.validateMenuItems(this.form.menu_items["4pm"].items);
        }
      },
      deep: true,
    },
  },
  computed: {
    location() {
      return this.$store.getters.location;
    },
    isDisabled() {
      if (this.location === "KE") {
        return this.form.menu_items["11am"].items.length === 0;
      }
      return (
        this.form.menu_items["4pm"].items.length === 0 &&
        this.form.menu_items["12pm"].items.length === 0
      );
    },
    mealItem() {
      return {
        name: this.form.service_type_name,
        side_types: this.form.side_types,
        ingredients: this.form.ingredients,
        food_tags: this.form.food_tags,
        chef_note: this.form.chef_note,
      };
    },
    mealItems() {
      return this.$store.getters.meal_item_list.map((item) => ({
        ...item,
        combos: [],
      }));
    },
    menuId() {
      return this.$route.params.id;
    },
    showPublishTitle() {
      if (this.action === "add") {
        return true;
      }

      return this.action === "edit" && this.data.is_draft;
    },
    showDraftButton() {
      let value = false;
      if (this.action === "add") {
        value = true;
      } else {
        if (this.data.is_draft) value = true;
      }

      return value;
    },
  },
  async created() {
    const itemsFetched = this.mealItems.length > 0;
    if (!itemsFetched && this.action !== "edit") {
      await this.getItems();
    }
    this.mealClassItems = this.mealItems;

    if (this.action === "edit") {
      Object.keys(this.form).forEach((key) => {
        if (key !== "menu_items") {
          this.form[key] = this.data[key];
        }
      });
      Object.keys(this.menuOptions).forEach((key) => {
        this.form.menu_items[key].items = this.data.menu_items
          .map((item) => ({ ...item, new: false }))
          .filter(
            (item) =>
              item?.extra.menu_item_category_id ===
              this.menuOptions[key].menu_item_category_id,
          );
      });
    }
    this.prefilling = false;
  },
  methods: {
    checkItems() {
      console.log(this.data, "data from server");
      console.log(this.form.menu_items, "mapped data");
    },
    updateMealItem({ key, value }) {
      console.log(value);
      this.form.menu_items[key].items = [...value];
    },
    async getItems() {
      await this.$store
        .dispatch(actions.GET_MEAL_ITEM_LIST)
        .then((response) => {
          if (response.data.status) {
            this.mealClassItems = response.data.data;
          }
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          this.$message.error(error.response.data.message);
        });
    },
    cancel() {
      this.$router.push({
        name: "services.service",
        params: { service: "meal" },
        query: { tab: "one-time" },
      });
    },
    next() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) {
          return false;
        }
        this.step = this.step === 1 ? 2 : 1;
      });
    },
    validateMenuItems(menuItems) {
      for (const item of menuItems) {
        if (item.image_url && !item.combos?.length) {
          this.isValidMenuItems = true;
        }

        if (item.combos && item.combos.length) {
          const comboList = item.combos;
          for (const combo of comboList) {
            if (!combo.image) {
              this.isValidMenuItems = false;
              return;
            }
          }
        }
        // const presetComboList = menuItems.map((item) => item.preset_combos);
        // for (const item of presetComboList.flat()) {
        //   if (!item.image) {
        //     this.isValidMenuItems = false;
        //     return;
        //   }
        // }
        this.isValidMenuItems = true;
      }

      this.isValidMenuItems = true;
    },
    addMenu() {
      this.showPublish = true;
    },
    editMenu() {
      this.showPublish = true;
    },
    draft() {
      this.action === "edit" ? this.update() : this.create(true);
    },
    payload(is_draft = true) {
      const payload = {
        is_draft: is_draft,
        menu_items: [],
      };
      Object.keys(this.form.menu_items).forEach((key) => {
        const newItems = this.form.menu_items[key].items.map((item) => {
          let comboArry = [];
          return {
            main_item_id: item.id,
            menu_item_category_id:
              this.form.menu_items[key].menu_item_category_id,
            side_items: item.combos
              ? item?.combos?.map((combo) => {
                let comboId = combo.comboId?.filter(
                  (id) => id != null && id != "",
                );
                let updatedPortions = this.action !== 'edit' ? combo?.portions?.map(portion => {
                  let updatedPortion = { ...portion };  // Clone the portion object
                  delete updatedPortion.name;  // Delete the 'name' property from the clone
                  return updatedPortion;  // Return the clone without the 'name' property
                }) : combo?.portions?.map(item => ({
                  "mfc_id": item.mfc.id,
                  "quantity": item.current_quantity
                }));
                return {
                  ids: comboId,
                  price: combo.price || 0,
                  portions: location !== 'KE' ? updatedPortions : null,
                  calorie: parseInt(combo.calorie),
                  ingredients: combo.ingredients,
                  tags: combo.food_tags,
                  image_url: combo?.image?.length ? combo.image : null,
                  combo_id: this.action === "edit" ? combo.combo_id : null
                };
              })
              : comboArry,
          };
        });
        payload.menu_items = [...payload.menu_items, ...newItems];
      });

      return payload;
    },
    publish() {
      this.showPublish = false;
      if (this.action === "add") {
        this.create(false);
      } else {
        this.update(false);
      }
    },
    create(is_draft = true) {
      if (is_draft) {
        this.updating = true;
      } else {
        this.adding = true;
      }

      const payload = this.payload(is_draft);
      console.log(payload, "I am payloadd")
      onetime
        .create(payload)
        .then((response) => {
          if (response.data.status) {
            this.$message.success(response.data.message);
            this.$emit("success");
            this.$router.push({
              name: "services.service",
              params: { service: "meal" },
              query: { tab: "one-time" },
            });
            this.updating = false;
            this.adding = false;
          }
        })
        .catch((error) => {
          this.$message.error(error.response.data.message);
          this.updating = false;
          this.adding = false;
        });
    },
    update(is_draft = true) {
      if (is_draft) {
        this.updating = true;
      } else {
        this.adding = true;
      }

      this.showPublish = false;

      const payload = this.payload(is_draft);

      onetime
        .update(this.menuId, payload)
        .then((response) => {
          if (response.data.status) {
            this.$message.success(response.data.message);
            this.$emit("success");
            this.$router.push({
              name: "services.service",
              params: { service: "meal" },
              query: { tab: "one-time" },
            });
            this.updating = false;
            this.adding = false;
          }
        })
        .catch((error) => {
          this.$message.error(error.response.data.message);
          this.updating = false;
          this.adding = false;
        });
    },
  },
};
</script>

<style scoped lang="scss">
.ingredient-add,
.combo-add {
  margin-top: 10px;
  display: flex;
  align-items: center;
  cursor: pointer;

  span {
    padding: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--eden-green-senary);
    border-radius: 10px;
    margin-right: 5px;

    i {
      color: var(--eden-green-primary);
      font-size: 0.75rem;
      font-weight: 60;
    }
  }

  p {
    color: var(--eden-green-primary);
    font-size: 0.825rem;
  }
}

.ingredient-remove {
  margin-left: 10px;
  margin-top: 20px;
  cursor: pointer;
}

.combo-empty {
  width: 50%;
  margin: 0 auto;
  border: 1px solid var(--eden-grey-quinary);
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 150px;

  .combo-add {
    margin-top: 0;
  }
}

.form-actions {
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
}

.wider {
  width: 75% !important;
}
</style>
