<template>
  <div v-if="location == 'KE'">
    <eden-form-section
      v-for="(keTime, ix) in keTimes"
      :key="ix"
      :title="`Meal Items `"
      :subtitle="`Select the main meal items that should be available on the one-time order menu.`"
    >
      <template slot="form-fields">
        <el-row type="flex">
          <el-col :md="24">
            <el-form-item label="">
              <el-select
                @change="updateMealItem($event, keTime)"
                :value="menuItems[keTime].items"
                multiple
                filterable
                placeholder="Select a main meal item"
                value-key="id"
              >
                <el-option
                  v-for="(item, index) in mealClassItems"
                  :value="item"
                  :key="index"
                  :label="item.name"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row
          v-if="menuItems[keTime].items.length"
          type="flex"
          class="flex-wrap"
        >
          <el-col :md="24">
            <div class="menu-meals">
              <div class="heading">
                <p>
                  Selected Meal Items ({{ menuItems[keTime].items.length }})
                </p>
                <p
                  v-if="menuItems[keTime].items.length"
                  class="is-underline text-cursor text-danger"
                  @click="clearSelectionWindow"
                >
                  Clear selection
                </p>
              </div>
              <div v-if="menuItems[keTime].items.length" class="meals">
                <el-row>
                  <el-col
                    :md="11"
                    class="item"
                    v-for="(meal, i) in menuItems[keTime].items"
                    :key="i"
                  >
                    <meal-item
                      :meal-item="meal"
                      @remove="removeItem(keTime, meal)"
                      @success="
                        (image_url, mealId) =>
                          updateImage(keTime, image_url, mealId)
                      "
                      :key="i"
                    />
                  </el-col>
                </el-row>
              </div>
            </div>
          </el-col>
        </el-row>
      </template>
    </eden-form-section>
  </div>
  <div v-else>
    <eden-form-section
      v-for="(ngTime, ix) in ngTimes"
      :key="ix"
      :title="`Meal Items`"
      :subtitle="`Select the main meal items that should be available on the one-time order menu.`"
    >
      <template slot="form-fields">
        <el-row type="flex">
          <el-col :md="24">
            <el-form-item label="">
              <el-select
                @change="updateMealItem($event, ngTime)"
                :value="menuItems[ngTime].items"
                multiple
                filterable
                placeholder="Select a main meal item"
                value-key="id"
              >
                <el-option
                  v-for="(item, index) in mealClassItems"
                  :value="item"
                  :key="index"
                  :label="item.name"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row
          v-if="menuItems[ngTime].items.length"
          type="flex"
          class="flex-wrap"
        >
          <el-col :md="24">
            <div class="menu-meals">
              <div class="heading">
                <p>
                  Selected Meal Items ({{ menuItems[ngTime].items.length }})
                </p>
                <p
                  v-if="menuItems[ngTime].items.length"
                  class="is-underline text-cursor text-danger"
                  @click="clearSelectionWindow(ngTime)"
                >
                  Clear selection
                </p>
              </div>
              <div v-if="menuItems[ngTime].items.length" class="meals">
                <el-row>
                  <el-col
                    :md="11"
                    class="item"
                    v-for="(meal, i) in menuItems[ngTime].items"
                    :key="i"
                  >
                    <meal-item
                      :meal-item="meal"
                      @remove="removeItem(ngTime, meal)"
                      @success="
                        (image_url, mealId) =>
                          updateImage(ngTime, image_url, mealId)
                      "
                      :key="i"
                    />
                  </el-col>
                </el-row>
              </div>
            </div>
          </el-col>
        </el-row>
      </template>
    </eden-form-section>

    <eden-confirm-dialog
      :title="`Clear ${selectionToClear} selection window`"
      button-text="Yes, clear"
      button-type="danger"
      :show.sync="showClearSelection"
      @confirm="clear"
    >
      <p>
        Are you sure you want to clear your {{ selectionToClear }} meal item
        selection?
      </p>
    </eden-confirm-dialog>
  </div>
</template>

<script>
import MealItem from "@/components/Services/Meal/MealItems/MealItem";

export default {
  name: "MenuOneTimeWindow",
  props: {
    action: {
      type: String,
      default: "add",
    },
    menuItems: {
      type: Object,
      default() {
        return [];
      },
    },
    mealClassItems: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  components: {
    MealItem,
  },
  data() {
    return {
      selectionToClear: "",
      showClearSelection: false,
      keTimes: ["11am"],
      ngTimes: ["4pm"],
    };
  },
  computed: {
    location() {
      return this.$store.getters.location;
    },
    setMenuItems: {
      get() {
        return this.menuItems;
      },
      set(value) {
        this.$emit("update:menuItems", value);
      },
    },
  },
  methods: {
    updateMealItem(value, key) {
      this.$emit("updateMealItem", { key, value });
    },
    removeItem(key, value) {
      const newList = this.menuItems[key].items.filter(
        (meal) => meal.id !== value.id,
      );

      this.updateMealItem(newList, key);
    },
    updateImage(key, image_url, mealId) {
      const mealClone = [...this.menuItems[key].items];
      const mealItemIndex = mealClone.findIndex((meal) => meal.id == mealId);
      mealClone[mealItemIndex].image_url = image_url;

      this.updateMealItem(mealClone, key);
    },
    clearSelectionWindow(key) {
      this.selectionToClear = key;
      this.showClearSelection = true;
    },
    clear() {
      this.updateMealItem([], this.selectionToClear);
      this.showClearSelection = false;
      this.selectionToClear = "";
    },
  },
};
</script>

<style lang="scss" scoped>
.menu-meals {
  background: rgba(0, 77, 41, 0.04);
  border-radius: 8px;
  padding: 12px 0px;

  .heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    margin: 0 8px;
    font-size: 12px;
  }

  .meals {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-top: 20px;

    .item {
      padding: 5px 0px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 12px;
      margin: 0 8px 8px;
      @media (max-width: 768px) {
        width: auto;
      }

      i {
        margin-left: 10px;
        color: #798b83;
        cursor: pointer;
      }
      &.invalid {
        color: #e36262;
        i {
          color: #e36262;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .eden-form-section {
    display: block;
  }
  .menu-meals {
    margin-bottom: 40px;
  }
  .el-col-24 {
    margin-top: 9px;
  }
  .eden-page-form {
    width: 100% !important;
    padding: 0 !important;
  }
}
</style>
