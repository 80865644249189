<template>
  <div>
    <el-dialog :title="title" :visible.sync="setShow" width="30%">
      <el-form :model="form" label-position="top" ref="form">
        <p v-if="action == 'edit' && item" class="text-center mb-20">
          <span class="editText text-bold">Editing {{ presetComboIndex }}:
            {{ item.combo ? item.combo : item.comboName }}
          </span>
        </p>
        <el-row>
          <el-col :span="24" class="is-flex is-justify-center">
            <eden-image-upload :image-size="60" :image-url.sync="form.image" :image-text="'combo image'"
              :image-folder="'services/meal/combo'" />
          </el-col>
        </el-row>
        <el-row v-for="side in item.side_types" :key="side" type="flex">
          <el-col :md="24" v-if="action === 'add'">
            <el-form-item :label="`${formatText(side.split('_').join(' '))}`" :prop="side" :rules="validateField()">
              <el-select v-model="form[side]" filterable clearable
                :placeholder="`Select this combo's ${side.split('_')[0]}`" @change="setComboName">
                <el-option v-for="sideItem in getSides(side)" :key="sideItem.id" :label="sideItem.name"
                  :value="sideItem?.id"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex">
          <el-col :span="24">
            <el-form-item label="Calorie Count" prop="calorie_count" :rules="validateField('Calorie Count')">
              <el-input type="text" v-model="form.calorie_count" v-decimal />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex">
          <el-col :span="24">
            <el-form-item label="Food tags">
              <el-select v-model="form.food_tags" multiple filterable allow-create placeholder="Add a tag">
                <el-option v-for="tagName in mealFoodTags" :key="tagName" :label="tagName"
                  :value="tagName.toLowerCase()"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex" v-if="location !== 'KE'">
          <el-col :span="24">
            <el-form-item label="Total Portions available" prop="current_quantity" :rules="validateField()">
              <el-input type="text" :value="totalQuantity" disabled />
            </el-form-item>
          </el-col>
        </el-row>
        <template v-if="location !== 'KE'">
          <el-row type="flex" v-for="(mfc, index) in form.portions" :key="mfc.id">
            <el-col :span="24">
              <el-form-item :label="`Portions available for ${mfc.name ? mfc.name : ''}`" prop="portion">
                <el-input v-model.number="form.portions[index].quantity" placeholder="Enter quantity" type="text"
                  v-decimal />
              </el-form-item>
            </el-col>
          </el-row>
        </template>

        <el-row type="flex">
          <el-col :span="24">
            <el-form-item label="Price" prop="price" :rules="validateField()">
              <el-input type="text" v-model="form.price" v-decimal />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex" v-if="action === 'add'">
          <el-col :span="24">
            <el-form-item label="Chef's notes" prop="chef_notes" :rules="validateField('Chef notes')">
              <el-input type="textarea" v-model="form.chef_notes" :autosize="{ minRows: 3, maxRows: 3 }" />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button plain @click="closeEvent">Cancel</el-button>
        <el-button v-if="action === 'add'" type="primary" @click="add" :loading="adding">Create combination</el-button>
        <el-button v-else type="primary" @click="update" :loading="updating">Save changes</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import service from "@/requests/services/service-type";
import onetime from "@/requests/services/meal/onetime/index";
import * as actions from "@/store/action-types";
export default {
  name: "OneTimeCombinationForm",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    action: {
      type: String,
      default: "add",
    },
    item: {
      type: Object,
      default() {
        return {};
      },
    },
    defaultPresetCombos: {
      type: Array,
      default() {
        return [];
      },
    },
    allItems: {
      type: Array,
      default() {
        return [];
      },
    },
    presetComboIndex: {
      type: Number,
      default: 0,
    },
    editIndex: {
      type: Number,
      default: null,
    },
    setCombos: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      form: {
        calorie_count: 0,
        food_tags: [],
        chef_notes: null,
        current_quantity: 0,
        service_type_name: "",
        image: null,
        meal_type: "",
        preset_combos: "",
        portions: [],
        price: 0,
        class_category: null,
        allergy: {
          seafood: "",
          shellfish: "",
          nut: "",
        },
        ingredients: [],
      },
      errors: {
        items: {},
      },
      mealFoodTags: [
        "Gluten free",
        "Contains soy",
        "Contains nuts",
        "Contains shellfish",
      ],
      adding: false,
      updating: false,
      combos: {},
    };
  },
  computed: {
    location() {
      return this.$store.getters.location;
    },
    setShow: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit("update:show", value);
      },
    },
    title() {
      return `${this.action === "add" ? "Add a" : "Edit"} combination`;
    },
    microFulfillmentCenters() {
      return this.$store.getters.micro_fulfillment_list;
    },
    totalQuantity() {
      if (this.form.portions && Array.isArray(this.form.portions)) {
        return this.form.portions.reduce((sum, portion) => sum + (portion.quantity || 0), 0);
      }
      return 0;
    },
    mealItems() {
      const items = this.parseData(this.$store.getters.service_types.meal);
      return this.sortArray({ data: items, property: "name" });
    },
    storedMealItems() {
      return this.$store.getters.meal_item_list;
    },
    mainSides() {
      return this.storedMealItems.filter((item) => {
        return item.side_category === "main_sides";
      });
    },
    proteinSides() {
      return this.storedMealItems.filter((item) => {
        return item.side_category === "protein_sides";
      });
    },
    otherSides() {
      return this.storedMealItems.filter((item) => {
        return item.side_category === "other_sides";
      });
    },
    sauceSides() {
      return this.storedMealItems.filter((item) => {
        return item.side_category === "sauce_sides";
      });
    },
  },
  watch: {
    show() {
      if (this.show && this.action === "edit") {
        this.form.name = this.item.name;
        this.initializePortions()
      }
      if (this.action === "add") {
        this.form = {
          name: "",
          calorie: "",
          image: "",
          price: "",
          food_tags: [],
          chef_note: "",
          // portions: [],
        };
        this.item.side_types?.forEach((side) => {
          this.form = {
            ...this.form,
            [side]: "",
          };
        });
      } else {
        this.form = {
          image: this.item.image_url,
          ...this.item,
          name: this.item.combo,
          current_quantity: this.item.total_available_portion,
          price: parseInt(this.item.price) || 0,
          calorie_count: this.item.calorie || 0,
          food_tags: this.item?.tags,
          portions: (this.item.portions && this.item.portions.length) ? this.item.portions.map(portion => {
            return {
              ...portion,
              quantity: portion.current_quantity || portion.quantity,
              id: portion.fulfillment_center_id || portion.id,
              name: portion.mfc ? portion.mfc.name : portion.name
            };
          }) : [],
        };
        this.initializePortions();
      }
    },
    item() {
      if (this.action === "add") {
        return;
      }
      if (this.item.side_types && this.item?.side_types?.length === 0) {
        this.form.calorie_count = this.item?.preset_combos[0]?.calorie;
        this.form.food_tags = this.item?.preset_combos[0]?.food_tags;
      } else {
        this.form.calorie_count = this.item?.calorie;
        this.form.food_tags = this.item?.tags?.length
          ? JSON.parse(this.item?.tags)
          : [];
      }
    },
    setCombos() {
      this.combos = this.setCombos;
    },
  },
  mounted() {
    if (!this.microFulfillmentCenters.length) {
      this.getMfc();
    } else {
      this.initializePortions();
    }
  },
  methods: {
    closeEvent() {
      this.setShow = false;
      // this.form.name = "";
    },
    getMfc() {
      this.loading = true;
      this.$store.dispatch(actions.GET_MICROFULFILLMENT_LIST).then(() => {
        this.loading = false;
        this.initializePortions();
      });
    },
    getSides(side) {
      switch (side) {
        case "main_sides":
          return this.mainSides;
        case "protein_sides":
          return this.proteinSides;
        case "other_sides":
          return this.otherSides;
        case "sauce_sides":
          return this.sauceSides;
        default:
          break;
      }
    },
    setComboName() {
      const { main_sides, protein_sides, other_sides, sauce_sides } = this.form;
      const main_side = this.mainSides.filter((item) => item.id === main_sides);
      const protein_side = this.proteinSides.filter(
        (item) => item.id === protein_sides,
      );
      const other_side = this.otherSides.filter(
        (item) => item.id === other_sides,
      );
      const sauce_side = this.sauceSides.filter(
        (item) => item.id === sauce_sides,
      );
      const combo = this.concatenateItems(
        main_side[0]?.name,
        protein_side[0]?.name,
        other_side[0]?.name,
        sauce_side[0]?.name,
      );
      this.form.name = `${this.item.name}, ${combo}`;
    },
    async initializePortions() {
      const newPortions = this.microFulfillmentCenters.map(mfc => {
        const existingPortion = this.form.portions.find(p => p.mfc_id === mfc.id);
        if (existingPortion) {
          return existingPortion;
        }
        const itemPortion = (this.item.portions && Array.isArray(this.item.portions)) ? this.item.portions.find(p => p.fulfillment_center_id === mfc.id) : undefined;

        if (itemPortion) {
          return {
            mfc_id: itemPortion.fulfillment_center_id,
            quantity: itemPortion.current_quantity || itemPortion.quantity,
            name: itemPortion.mfc ? itemPortion.mfc.name : itemPortion.name
          };
        }

        // If neither existingPortion nor itemPortion is found, create a new portion with quantity of 0
        return { mfc_id: mfc.id, quantity: 0, name: mfc.name };
      });

      this.form.portions = newPortions;
    },
    payload() {
      if (this.action === "add") {
        let payload = this.parseData(this.form);
        // console.log(this.item, "add combo");
        payload.service = this.item?.service_name;
        payload.service_type_name = this.item?.name;
        payload.service_type_category = this.item?.type;
        payload.meal_type = this.$route.path.includes("/edit-onetime")
          ? this.item?.meal_type
          : this.item?.meal_type[0];
        payload.max_no_of_sides = this.item?.side_types?.length;
        payload.side_types = this.item?.side_types?.length
          ? this.item.side_types.toString()
          : null;
        let preset_combos = [
          {
            protein_sides: payload.protein_sides,
            main_sides: payload.main_sides,
            sauce_sides: payload.sauce_sides,
            other_sides: payload.other_sides,
            image: this.item?.image,
            calorie: payload.calorie_count,
            food_tags: payload.food_tags,
            chef_note: payload.chef_notes,
            new: true,
          },
        ];
        payload.preset_combos = JSON.stringify([
          ...preset_combos,
          ...this.defaultPresetCombos,
        ]);

        // console.log(payload.preset_combos, "vkk");
        payload.main_sides = null;
        payload.protein_sides = null;
        payload.sauce_sides = null;
        payload.other_sides = null;
        const allergy = payload?.allergyTags;
        payload.allergy = {
          seafood: allergy ? allergy.seafood : "not-included",
          shellfish: allergy ? allergy.shellfish : "not-included",
          nut: allergy ? allergy.nut : "not-included",
        };
        payload.combo_image_url = this.item?.combo_image_url;
        payload.image = this.item?.image_url;
        payload.id = this.item.id;
        if (payload.meal_type === "side") {
          payload.max_no_of_sides = null;
        }
        if (this.$route.path.includes("/edit-onetime")) {
          // The current route is /edit-onetime
          const clonedMealItems = this.$store.getters.meal_item_list;
          const mealItem = clonedMealItems.find(
            (item) => item.id == this.item.id,
          );
          payload.service = mealItem?.service_name;
          payload.service_type_category = mealItem?.type;
        }
        return payload;
      }
      return {
        calorie: this.form.calorie_count,
        ingredients: this.form?.ingredients === "[]" ? [] : this.form?.ingredients,
        tags: this.form.food_tags,
        image_url: this.form.image,
        // quantity: this.form.current_quantity,
        price: this.form.price,
        portions: this.form.portions,
      };
    },
    add() {
      this.adding = true;
      const payload = this.payload();
      console.log(payload);
      service
        .update(this.item.id, payload)
        .then((response) => {
          if (response.data.status) {
            this.adding = false;
            this.$message.success("Meal Combo added successfully");

            this.$emit("newCombos", {
              ...this.form,
              calorie: this.form.calorie_count,
              // image: JSON.parse(payload.preset_combos)[0]?.image,
              image: this.form.image,
              serial_identifier:
                response.data.data.newly_added_combos[0]?.serial_identifier,
              mealId: response.data.data.id,
              new_preset_combo: response.data.data?.preset_combos[0],
            });

            this.closeEvent();
            this.form = "";
            this.item.image = "";
          } else {
            this.$message.error(response.data.message);
            this.adding = false;
          }
        })
        .catch((error) => {
          this.adding = false;
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
      return true;
      // });
    },
    update() {
      if (this.$route.path.includes("/one-time-order-menu")) {
        this.adding = true;
        this.$emit("newCombos", this.form);
        this.adding = false;
      } else {
        this.adding = true;
        const payload = this.payload();
        console.log(payload, this.form)
        onetime
          .updateCombo(this.form.menu_id, this.form.id, payload)
          .then((response) => {
            if (response.data.status) {
              this.adding = false;
              response.data.comboName = this.form.comboName;
              this.$message.success(response.data.message);
              this.$emit("newCombos", response.data, this.form.comboName);
              this.closeEvent();
              this.form = "";
              this.item.image = "";
            } else {
              this.$message.error(response.data.message);
              this.adding = false;
            }
          })
          .catch((error) => {
            this.adding = false;
            const errorMessage = error.response.data;
            if (errorMessage.errors) {
              const errorKeys = Object.keys(errorMessage.errors);
              this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
            } else {
              this.$message.error(errorMessage.message);
            }
          });

        this.adding = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  cursor: pointer;
}

.editText {
  background-color: var(--eden-orange-septenary);
  border-radius: 8px;
  padding: 8px;
}
</style>
