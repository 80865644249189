<template>
  <div>
    <el-dialog :title="title" :visible.sync="setShow" width="30%">
      <el-form :model="form" label-position="top" ref="form">
        <el-row type="flex">
          <el-col :span="24">
            <el-form-item prop="item">
              <el-input
                type="text"
                v-model="form.item"
                placeholder="Search for a combination"
                @input="search"
              />
              <div
                class="mt-10 mb-10 text-secondary font-sm text-cursor"
                @click="addCombination"
              >
                <i class="eden-icon-add font-sm mr-1 text-secondary" />
                Create a new combination
              </div>
              <div
                v-for="(combo, i) in combos?.filter(
                  (it) => it?.combo != null && it?.combo != '',
                )"
                :key="i"
                class="is-flex is-align-center card font-sm mb-10"
              >
                <el-checkbox
                  v-model="combo.checked"
                  @input="enableAdd(combo)"
                  class="mr-1"
                />
                <div class="eden-meal--image">
                  <img
                    v-if="validateCombos(combo.image)"
                    :src="getThumbnailFromCloudinary(combo.image)"
                    alt="Image"
                  />
                  <img
                    v-else
                    :src="getImage('default/image-upload-icon.svg')"
                    :alt="'Image'"
                    :class="'default meal-image'"
                  />
                </div>
                <div>
                  <p style="display: block">{{ item.name }} with</p>
                  <p class="text-bold">{{ combo.combo }}</p>
                </div>
              </div>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button plain @click="closeEvent">Cancel</el-button>
        <el-button
          type="primary"
          @click="add"
          :loading="adding"
          :disabled="!showAdd"
          >Add</el-button
        >
      </span>
    </el-dialog>
    <one-time-combination-form
      :show.sync="combination.visibility"
      :action="combination.action"
      :item="combination.data"
      :defaultPresetCombos="defaultPresetCombos"
      @newCombos="updateCombinations"
      :setCombos="setCombos"
      :editIndex="editIndex"
    />
  </div>
</template>

<script>
import OneTimeCombinationForm from "@/components/Services/Meal/MealOneTimeOrder/NewMenuOneTime/OneTimeCombinationForm";

export default {
  name: "OneTimeCombinations",
  components: { OneTimeCombinationForm },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      default() {
        return {};
      },
    },
    editIndex: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      form: {
        item: "",
      },
      adding: false,
      updating: false,
      combos: [],
      combination: {
        action: "add",
        visibility: false,
        data: {},
      },
      allCombos: [],
      newComboItem: {},
      defaultPresetCombos: [],
      setCombos: {},
      showAdd: false,
      checkedCombos: {},
    };
  },
  computed: {
    setShow: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit("update:show", value);
      },
    },
    title() {
      return this.item.name;
    },
    mealItems() {
      const items = this.parseData(this.$store.getters.service_types.meal);
      return this.sortArray({ data: items, property: "name" });
    },
    storedMealItems() {
      return this.$store.getters.meal_item_list;
    },
    mainSides() {
      return this.storedMealItems.filter((item) => {
        return item.side_category === "main_sides";
      });
    },
    proteinSides() {
      return this.storedMealItems.filter((item) => {
        return item.side_category === "protein_sides";
      });
    },
    otherSides() {
      return this.storedMealItems.filter((item) => {
        return item.side_category === "other_sides";
      });
    },
    sauceSides() {
      return this.storedMealItems.filter((item) => {
        return item.side_category === "sauce_sides";
      });
    },
  },
  watch: {
    show() {
      if (this.show) {
        this.checkedCombos[this.item.id] = [];
        this.item.combos?.forEach((item) => {
          this.checkedCombos[this.item.id] = [
            ...this.checkedCombos[this.item.id],
            item,
          ];
        });

        this.formatCombos();
      }
    },
  },
  created() {
    console.log(this.item, "item to map");

    this.formatCombos();
  },
  methods: {
    validateCombos(combosObj) {
      if (!combosObj || !combosObj.length) {
        return false;
      }
      return Object.values(combosObj).every((value) => {
        if (Array.isArray(value)) {
          return Boolean(value.length);
        }

        return Boolean(value);
      });
    },
    enableAdd(value) {
      if (value.checked) {
        this.checkedCombos[this.item.id].push(value);
      } else {
        this.checkedCombos[this.item.id] = this.checkedCombos[
          this.item.id
        ].filter((combo) => combo.id !== value.id);
      }
      this.showAdd = !!this.checkedCombos[this.item.id].length;
    },
    search() {
      if (this.form.item) {
        this.combos = this.allCombos.filter((item) =>
          item.combo.toLowerCase().includes(this.form.item.toLowerCase()),
        );
      } else {
        this.combos = this.allCombos;
      }
    },
    updateCombinations(newCombo) {
      console.log(newCombo, "whqattt")
      this.newComboItem = {
        calorie: parseInt(newCombo.calorie_count),
        checked: false,
        combo: this.setComboName(newCombo),
        comboId: [
          newCombo.main_sides,
          newCombo.protein_sides,
          newCombo.sauce_sides,
          newCombo.other_sides,
        ].filter((id) => id != null && id != ""),
        food_tags: newCombo.food_tags,
        id: 0,
        image: newCombo.image,
        ingredients: [],
        mealId: newCombo.mealId,
      };

      this.combos.unshift(this.newComboItem);

      this.setShow = true;
      if (newCombo.new_preset_combo) {
        const resultToEmit = {
          new_preset_combo: newCombo.new_preset_combo,
          mealId: this.newComboItem.mealId,
          new_preset_combo_full: {
            main_sides:
              this.mainSides.find(
                (item) => item.id === newCombo.new_preset_combo?.main_sides,
              )?.name || "",
            other_sides:
              this.otherSides.find(
                (item) => item.id === newCombo.new_preset_combo?.other_sides,
              )?.name || "",
            protein_sides:
              this.proteinSides.find(
                (item) => item.id === newCombo.new_preset_combo?.protein_sides,
              )?.name || "",
            sauce_sides:
              this.sauceSides.find(
                (item) => item.id === newCombo.new_preset_combo?.sauce_sides,
              )?.name || "",
          },
        };

        this.$emit("addPresetCombo", resultToEmit);
      }
    },
    addCombination() {
      this.combination = {
        ...this.combination,
        data: this.item,
        visibility: true,
      };
      this.defaultPresetCombos = this.item.preset_combos || [];
      this.defaultPresetCombos = this.defaultPresetCombos?.map((item) => {
        return { ...item, new: false };
      });
    },
    closeEvent() {
      this.setShow = false;
      this.form.item = "";
    },
    add() {
      let setCombos = {
        id: this.item.id,
        combos: this.checkedCombos[this.item.id],
      };
      this.setCombos = setCombos;
      console.log(setCombos, "checkedCombos");
      this.$emit("checkedCombos", setCombos);
      this.closeEvent();
    },
    setComboName(combo) {
      const { main_sides, protein_sides, other_sides, sauce_sides } = combo;

      const main_side = this.mainSides.find((item) => item.id === main_sides);
      const protein_side = this.proteinSides.find(
        (item) => item.id === protein_sides,
      );
      const other_side = this.otherSides.find(
        (item) => item.id === other_sides,
      );
      const sauce_side = this.sauceSides.find(
        (item) => item.id === sauce_sides,
      );

      const name = this.concatenateItems(
        main_side?.name,
        protein_side?.name,
        other_side?.name,
        sauce_side?.name,
      );

      return name;
    },
    formatCombos() {
      this.allCombos = this.item.preset_combos?.map((combo, index) => {
        let availableSidesIdx = Object.keys(combo)
          .filter((item) => item.includes("sides"))
          .sort((a, b) => a.localeCompare(b));

        // For preset_combos
        let sideItemIdx = "";
        availableSidesIdx?.map((side) => {
          if (combo[side]) sideItemIdx += "," + combo[side];
        });

        const result = {
          id: index,
          mealId: this.item.id,
          serial_identifier: combo.serial_identifier,
          combo: this.setComboName(combo),
          image: combo.image,
          comboId: [
            combo.main_sides,
            combo.protein_sides,
            combo.sauce_sides,
            combo.other_sides,
          ].filter((e) => e),
          ingredients: [combo.ingredients].filter((e) => e),
          food_tags: [combo.food_tags].filter((e) => e),
          calorie: combo.calorie,
          checked: false,
        };

        result.checked = this.checkedCombos[this.item.id]?.some(
          (comboCheck) => {
            // For checked_combos
            let sideItemId = comboCheck.comboId?.join(",");
            return sideItemIdx.slice(1) === sideItemId;
          },
        );
        return result;
      });
      this.combos = this.allCombos;
    },
  },
};
</script>

<style lang="scss" scoped>
.label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  cursor: pointer;
}
.eden-meal--image {
  height: 36px;
  width: 36px;
  border-radius: 100%;
  background: #f4f4f9;
  margin-right: 20px;

  img {
    height: 100%;
    width: 100%;
    object-fit: contain;
    border-radius: 8px;
  }

  + div {
    width: 70%;
  }
}

.meal-image {
  background: #e9fbf2;
}
</style>
