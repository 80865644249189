<template>
  <div>
    <eden-form-section title="Combinations Review"
      subtitle="Select the combinations you want customers to pick from in this menu."></eden-form-section>

    <div v-for="(menuTime, menuTimeKey, indx) in menuItems" :key="indx">
      <el-row class="firstMeal is-flex-wrap" v-for="(meal, index) in menuTime.items" :key="index" type="flex">
        <one-time-combination-form :show.sync="showComboForm" :action="'edit'" :item="comboToEdit"
          :allItems="menuTime.items" :presetComboIndex="presetComboIndex" @newCombos="updateCombinations"
          @success="updateCombinations" />
        <el-col :md="24">
          <el-form-item>
            <div class="card mb-10">
              <div class="header mb-10">
                <span class="text-bold">{{ meal?.name }}
                  <!-- <span>
                    <el-tag type="info">{{ menuTimeKey }}</el-tag>
                  </span> -->
                </span>
                <span class="text-cursor" @click="removeItem(meal.id, menuTimeKey)"><i
                    class="eden-icon-remove font-sm mr-1" /> Remove meal
                  item</span>
              </div>
              <div class="is-flex is-align-center font-sm justify-between" v-if="meal.combos?.length > 0">
                <el-row type="flex" class="is-flex-wrap">
                  <el-col v-for="(combo, i) in meal.combos" :key="i" :md="12">
                    <div class="combo-card" :class="!validateImage(combo?.image) ? 'side-meal' : ''">
                      <div class="is-flex is-flex is-align-center">
                        <el-checkbox v-if="combo.combo != meal.name" class="mr-1" />
                        <div class="eden-meal--image">
                          <img v-if="validateImage(combo.image)" :src="getThumbnailFromCloudinary(combo.image)"
                            alt="Image" />
                          <img v-else :src="getImage('default/image-upload-icon.svg')" :alt="'Image'"
                            :class="'default meal-image'" />
                        </div>
                        <div class="additional-info">
                          <div class="text-bold mr-10">
                            {{ combo.combo }}
                          </div>
                          <div>
                            {{
                              validateFoodTags(combo?.food_tags)
                              ? `Food tags: ${formatToTitleCase(
                                combo?.food_tags
                                  ?.toString()
                                  .split(",")
                                  .join(", "),
                              )}`
                              : "No tags added"
                            }}
                          </div>
                          <div>
                            {{
                              validateCalorie(combo?.calorie)
                              ? `${combo?.calorie} calories`
                              : "No calorie added "
                            }}
                          </div>
                          <div class="text-bold">
                            Price: {{
                              formatPrice(combo.price)
                            }}
                          </div>
                        </div>
                        <div class="text-cursor">
                          <span v-if="action === 'add'" @click="editCombo(combo, i, meal, menuTimeKey)"
                            class="eden-icon-edit font-sm mr-1"></span>
                          <span @click="removeCombo(index, i, menuTimeKey)" v-if="combo.combo != meal.name"
                            class="eden-icon-delete font-sm"></span>
                        </div>
                      </div>
                      <div class="divider" v-if="action === 'add'">
                        <div class="is-flex py-2 mt-1 is-align-center">
                          <div class="is-inline ml-1 font-base">
                            <p class="combo-text"  v-if="location !== 'KE'">
                              Portions available for same-day delivery:
                              <span class="text-bold">{{
                                calculateComboQuantity(combo)
                              }}</span>
                            </p>
                          </div>
                        </div>
                      </div>
                      <div class="is-flex py-2 mt-1 is-align-center" v-for="mfc in combo.portions" :key="mfc.id">
                        <div class="is-inline ml-1 font-base">
                          <p class="combo-text" v-if="action !== 'edit'">
                            Portions left for {{ mfc.name }}:
                            <span class="text-bold">{{
                              mfc.quantity
                            }}</span>
                          </p>

                          <p class="combo-text" v-else>
                            Portions left for {{ mfc.mfc.name }}:
                            <span class="text-bold">{{
                              mfc.current_quantity
                            }}</span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </el-col>
                </el-row>
              </div>
              <div v-if="meal?.side_types?.length > 0">
                <div class="text-center mt-10 mb-10 text-secondary text-cursor"
                  @click="setCombination(meal, menuTimeKey)">
                  <i class="eden-icon-add font-sm mr-1 text-secondary" /> Add a
                  combination
                </div>
              </div>
              <div class="is-flex is-align-center font-sm justify-between w-50" v-else>
                <div class="combo-card is-flex is-align-center"
                  :class="!validateImage(meal.image_url) ? 'side-meal' : ''">
                  <div class="eden-meal--image">
                    <img v-if="validateImage(meal.image_url)" :src="getThumbnailFromCloudinary(meal.image_url)"
                      alt="Image" />
                    <img v-else :src="getImage('default/image-upload-icon.svg')" :alt="'Image'"
                      :class="'default meal-image'" />
                  </div>
                  <div class="additional-info">
                    <div class="text-bold mr-10">
                      {{ meal.name }}
                    </div>
                    <div>
                      {{
                        validateFoodTags(meal?.preset_combos?.[0]?.tags)
                        ? `Food tags: ${formatToTitleCase(
                          meal?.preset_combos[0]?.tags
                            .toString()
                            .split(",")
                            .join(", "),
                        )}`
                        : "No food tags added"
                      }}
                    </div>
                    <div>
                      {{
                        validateCalorie(meal?.preset_combos?.[0]?.calorie)
                        ? `${meal?.preset_combos[0]?.calorie} calories`
                        : "No calories added"
                      }}
                    </div>
                    <!-- <div>
                           Price: {{
                             meal.preset_combos[0]?.price
                            }}
                          </div> -->
                  </div>
                  <div class="text-cursor">
                    <el-tooltip class="item" effect="dark"
                      content="Kindly edit this meal item from the meal item page as it has no side types"
                      placement="top-start">
                      <span style="cursor: not-allowed" class="eden-icon-edit font-sm mr-1"></span>
                    </el-tooltip>
                  </div>
                </div>
              </div>
            </div>
          </el-form-item>
        </el-col>
      </el-row>
    </div>

    <one-time-combinations :show.sync="showCombinations" :item="menuItem" @success="addCombination"
      @checkedCombos="checkCombos" @addPresetCombo="addPresetCombo" />

    <eden-confirm-dialog title="Remove combo item?" button-text="Yes, remove" button-type="danger"
      :show.sync="showRemoveCombo" @confirm="deleteCombo">
      <p>Are you sure you want to remove this combo from the menu?</p>
    </eden-confirm-dialog>

    <eden-confirm-dialog title="Remove meal item?" button-text="Yes, remove" button-type="danger"
      :show.sync="showRemoveItem" @confirm="deleteItem">
      <p>
        Are you sure you want to remove this
        {{ selectionTimeInAction }} meal item from the menu?
      </p>
    </eden-confirm-dialog>
  </div>
</template>

<script>
import * as actions from "@/store/action-types";
import OneTimeCombinations from "@/components/Services/Meal/MealOneTimeOrder/NewMenuOneTime/OneTimeCombinations";
import OneTimeCombinationForm from "@/components/Services/Meal/MealOneTimeOrder/NewMenuOneTime/OneTimeCombinationForm";
export default {
  name: "OneTimeCombinationsReview",
  props: {
    action: {
      type: String,
      default: "add",
    },
    menuItems: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  components: {
    OneTimeCombinations,
    OneTimeCombinationForm,
  },
  data() {
    return {
      selectionTimeInAction: "",
      selectionTimeComboToDelete: "",
      quantity: 0,
      showCombinations: false,
      menuItem: {},
      loading: false,
      // cutOffVal: true,
      showRemoveItem: false,
      showRemoveCombo: false,
      itemId: null,
      comboIndex: -1,
      mealIndex: -1,
      presetComboIndex: 0,
      showComboForm: false,
      comboToEdit: {},
    };
  },
  created() {
    Object.keys(this.menuItems).forEach((key) => {
      this.menuItems[key].items.sort((a, b) => {
        let firstItem = a.name.toLowerCase(),
          nextItem = b.name.toLowerCase();

        if (firstItem < nextItem) {
          return -1;
        }
        if (firstItem > nextItem) {
          return 1;
        }
        return 0;
      });
    });
    // this.setMenuItems.map((item) => {
    //   let combos;
    //   if (
    //     item.class_category != null &&
    //     item.class_category.includes("juices")
    //   ) {
    //     combos = [{ combo: item.name, image: item.image_url }];
    //   } else {
    //     combos = [];
    //   }
    //   item.combos = combos;
    //   return item;
    // });
    // this.setMenuItemsSelect.map((item) => {
    //   let combos;
    //   if (
    //     item.class_category != null &&
    //     item.class_category.includes("juices")
    //   ) {
    //     combos = [{ combo: item.name, image: item.image_url }];
    //   } else {
    //     combos = [];
    //   }
    //   item.combos = combos;
    //   return item;
    // });

    // this.combo.food_tags = this.combo.food_tags.toString();
  },
  computed: {
    location() {
      return this.$store.getters.location;
    },
  },
  methods: {
    calculateComboQuantity(combo) {
      if (combo.portions && Array.isArray(combo.portions)) {
        return combo.portions.reduce((sum, portion) => sum + (portion.quantity || 0), 0);
      }
      return 0;
    },
    validateFoodTags(foodTags) {
      if (!foodTags || !foodTags.length) {
        return false;
      }
      if (
        typeof foodTags === "string" &&
        foodTags.startsWith("[") &&
        foodTags.endsWith("]")
      ) {
        const foodTagsList = JSON.parse(foodTags).flat();
        if (!foodTagsList.length) {
          return false;
        }
      }
      return Object.values(foodTags).every((value) => {
        if (Array.isArray(value)) {
          return Boolean(value.length);
        }
        return Boolean(value);
      });
    },
    validateCalorie(calorie) {
      return calorie && Number(calorie);
    },
    validateCombos(combos) {
      if (!combos?.image) {
        return false;
      }
      if (
        !combos &&
        !combos?.food_tags?.length &&
        !combos?.calorie &&
        !combos?.length
      ) {
        return false;
      }

      return true;
    },
    emitUpdates({ key, value }) {
      this.$emit("updateMealItem", { key, value });
    },
    validateImage(image) {
      return Boolean(image);
    },
    addPresetCombo({ new_preset_combo, new_preset_combo_full, mealId }) {
      const listOfItems = this.menuItems[this.selectionTimeInAction].items;
      const meal = listOfItems.find((item) => item.id === mealId);
      const mealIndex = listOfItems.indexOf(meal);

      listOfItems.splice(mealIndex, 1, {
        ...meal,
        preset_combos: [new_preset_combo, ...meal.preset_combos],
        preset_combos_full: [new_preset_combo_full, ...meal.preset_combos_full],
      });

      this.emitUpdates({ key: this.selectionTimeInAction, value: listOfItems });
    },
    updateCombinations(newCombo) {
      console.log(newCombo, "new")
      const itemIndex = this.menuItems[
        this.selectionTimeInAction
      ].items.findIndex((item) => item.id == this.comboToEdit.meal_id);

      const listOfItems = this.menuItems;
      listOfItems[this.selectionTimeInAction].items[itemIndex].combos[
        this.presetComboIndex
      ] = {
        ...listOfItems[this.selectionTimeInAction].items[itemIndex].combos[
        this.presetComboIndex
        ],
        ...newCombo,
      };

      this.emitUpdates({
        key: this.selectionTimeInAction,
        value: listOfItems[this.selectionTimeInAction].items,
      });

      this.showComboForm = false;
    },
    checkCombos(value) {
      const listOfItems = this.menuItems[this.selectionTimeInAction].items;
      const meal = listOfItems.find((item) => item.id === value.id);
      const mealIndex = listOfItems.indexOf(meal);

      listOfItems.splice(mealIndex, 1, {
        ...meal,
        combos: value.combos,
      });

      this.emitUpdates({ key: this.selectionTimeInAction, value: listOfItems });
    },
    editCombo(combo, index, meal, key) {
      this.comboToEdit = {
        ...combo,
        meal_id: meal.id,
        meal_type: meal?.extra?.meal_type,
        extra: meal.extra,
        side_types: meal.side_types,
      };
      this.selectionTimeInAction = key;
      this.presetComboIndex = index;
      this.showComboForm = true;
    },
    removeCombo(index, i, key) {
      this.comboIndex = i;
      this.mealIndex = index;
      this.selectionTimeInAction = key;

      this.showRemoveCombo = true;
    },
    removeItem(index, key) {
      this.itemId = index;
      this.selectionTimeInAction = key;
      this.showRemoveItem = true;
    },
    deleteCombo() {
      this.menuItems[this.selectionTimeInAction].items[
        this.mealIndex
      ].combos.splice(this.comboIndex, 1);

      this.showRemoveCombo = false;
    },
    deleteItem() {
      const listOfItems = this.menuItems;
      listOfItems[this.selectionTimeInAction].items = listOfItems[
        this.selectionTimeInAction
      ].items.filter((meal) => meal.id !== this.itemId);

      this.emitUpdates({
        key: this.selectionTimeInAction,
        value: listOfItems[this.selectionTimeInAction].items,
      });
      this.showRemoveItem = false;
    },
    setCombination(value, key) {
      this.selectionTimeInAction = key;
      this.menuItem = value;

      this.showCombinations = true;
    },

    addCombination() {
      // console.log("Pass");
    },
    getItems() {
      this.$store
        .dispatch(actions.GET_MEAL_ITEM_LIST)
        .then((response) => {
          if (response.data.status) {
            this.mealClassItems = response.data.data;
          }
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          this.$message.error(error.response.data.message);
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.card {
  padding: 3% 4%;
  background-color: var(--eden-grey-octonary);
  border-radius: 8px;
}

.header {
  display: flex;
  justify-content: space-between;
}

.menu-meals {
  background: rgba(0, 77, 41, 0.04);
  border-radius: 8px;
  padding: 12px 0px;

  .heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    margin: 0 8px;
    font-size: 12px;
  }

  .meals {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-top: 20px;

    .item {
      padding: 5px 0px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 12px;
      margin: 0 8px 8px;
      width: auto;

      i {
        margin-left: 10px;
        color: #798b83;
        cursor: pointer;
      }

      &.invalid {
        color: #e36262;

        i {
          color: #e36262;
        }
      }
    }
  }
}

.eden-meal--image {
  height: 36px;
  width: 36px;
  border-radius: 8px;
  background: #f4f4f9;
  margin-right: 20px;

  img {
    height: 100%;
    width: 100%;
    object-fit: contain;
    border-radius: 8px;
  }

  +div {
    width: 70%;
  }
}

.combo-card {
  background-color: var(--white);
  padding: 8px;
  border-radius: 8px;
  width: 95%;
  justify-content: space-between;
  margin-top: 9px;

  .divider {
    border-top: 1px solid #e2e9e6;
    margin-top: 12px;
  }
}

.additional-info {
  line-height: 18px;
}

.w-120 {
  width: 120%;
}

.w-50 {
  width: 50%;
}

.meal-image {
  background: #e9fbf2;
}

.is-flex-wrap {
  width: 100%;
}

.side-meal {
  border: 1px solid #f4b7bc;
}

.info-text {
  color: #0f241b;
  font-size: 11.5px;
}
</style>
